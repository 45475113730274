<template>
  <section class="section section-content">
    <div class="container">
      <h1>WEBSITE TERMS & CONDITIONS</h1>
      <h2>GENERAL INFORMATION</h2>
      <p>
        This website www.grapevine.travel (the <strong>site</strong>) is run and
        maintained by Your Grapevine Ltd (“<strong>we</strong>”). Our email
        address is
        <a href="mailto:info@grapevine.travel">info@grapevine.travel.</a>
      </p>
      <p>
        These Website Terms and Conditions (“<strong
          >Terms and Conditions</strong
        >”) cover the terms on which you may use the site and conduct your
        business.
      </p>
      <p>
        Please read these Terms and Conditions carefully before you start to use
        the site. By using the site, you accept and agree to be bound and abide
        by these Terms and Conditions, our
        <router-link to="/privacy-policy">
          Privacy Notice.
        </router-link>
        If you do not accept them, please do not use the site.
      </p>
      <h2>USER RIGHTS OF ACCESS</h2>
      <p>
        You have permission for temporary, non-exclusive use of the site. We
        reserve the right to withdraw or change the content of the site and
        these Terms and Conditions at any time without notifying you and without
        having any legal responsibility towards you.
      </p>
      <p>
        You are not allowed to copy, modify, duplicate, create derivative works
        from, frame, mirror, republish, display, transmit, or distribute all or
        any part of the site.
      </p>
      <p>
        You are also not allowed to license, sell, rent, lease, transfer,
        assign, distribute, display, disclose, or otherwise commercially
        exploit, or otherwise make the site and/or any documents or online
        resources on the site to any third party.
      </p>
      <p>
        You are responsible for configuring your own computer and software to
        access the material and content on our site including downloadable
        files. We do not warrant that the way we deliver data to you is
        compatible with your software or with the way your mobile device,
        computer or tablet is configured. Although we do our best to protect our
        site, we are not responsible for any viruses, bugs or similar issues. We
        advise that you use your own virus protection software to protect
        yourself.
      </p>
      <p>
        You must treat all identification codes, passwords and other security
        information that you obtain from use of the site and (where applicable)
        for you to access parts of the site, as confidential. If we think you
        have failed to keep confidentiality, we may disable any such
        information, including your passwords and other codes.
      </p>
      <p>
        You agree to follow our
        <router-link to="/acceptable-use-policy">
          Acceptable Use Policy.
        </router-link>
      </p>
      <p>
        If you allow anyone else to use our site, you must make sure that they
        read these Terms and Conditions first, and that they agree to and follow
        them.
      </p>
      <p>
        If you do not use the site according to the law and these Terms and
        Conditions, we may suspend your usage, or stop it completely.
      </p>
      <p>
        We frequently update the site and make changes to it, but we have no
        duty to do this. This means that content on the site may be out dated or
        incorrect. We will have no liability to you for any losses or damage you
        may suffer from your reliance on any of our materials.
      </p>
      <p>
        We do not save your credit card details or share customer details with
        any third parties and we follow our Privacy Notice in handling
        information about you. By using the site, you agree to us handling this
        information and confirm that data you provide is accurate.
      </p>
      <p>
        Our site uses cookies. More information is provided in our Cookie
        Policy.
      </p>
      <h2>CONFIDENTIALITY</h2>
      <p>
        Personal information or business information that you supply to us
        through the site, other than information that is in the public domain,
        will be treated confidentially and in line with our Privacy Notice.
        Confidential information will not be disclosed to any third party,
        including for the purposes of marketing, without your prior permission.
        We will only disclose your information if it is necessary for the
        performance of our services or where so required by law.
      </p>
      <h2>VARIATION</h2>
      <p>
        We may change these Terms and Conditions from time to time. Please
        ensure that you check for any changes regularly, as you are bound by
        them if you use of this site.
      </p>
      <h2>INTELLECTUAL PROPERTY RIGHTS</h2>
      <p>
        We are the owner or licensee of all intellectual property rights in the
        site including any databases that hold relevant information about the
        site. They are protected by copyright or trademark registration and you
        may only use any such material and the documents in line with these
        Terms and Conditions and this paragraph specifically. If you do not use
        the materials in line with these Terms and Conditions and this paragraph
        specifically, you lose your right to use our site, and must destroy or
        return any copies of documents you have made of it or any part of it. We
        reserve all our rights conferred to us by law to remedy any such breach.
      </p>

      <h2>OUR RESPONSIBILITY TO YOU</h2>
      <p>
        We do not guarantee the accuracy of material on our site or the
        materials uploaded by our users, it is for your general information and
        you are responsible for the way you use its content.
      </p>
      <p>We shall not limit or exclude our liability for:</p>

      <ul>
        <li>death or personal injury;</li>
        <li>fraud or fraudulent misrepresentation; or</li>
        <li>
          any act, omission or matter, liability for which may not be excluded
          or limited under any Applicable Law.
        </li>
      </ul>

      <p>
        We shall not be liable to you for any indirect, special or consequential
        loss or damage, including:
      </p>

      <ul>
        <li>loss of profit;</li>
        <li>loss of goodwill;</li>
        <li>loss of savings; or</li>
        <li>loss of contract.</li>
      </ul>

      <p>
        We also exclude, but only as far as legally possible, all terms and
        warranties or promises implied by law or by statute.
      </p>
      <p>
        Although we try to make the site available at all times, we do not
        warrant that your use of the site will be uninterrupted. We are not
        responsible for any loss or damage you may suffer resulting from any
        interruptions, errors or the transfer of data and you acknowledge that
        the site may be subject to limitations, delays and other issues.
      </p>
      <p>
        Any duty of care owed to you by us is owed to you alone and no duty of
        care is owed to any third party and we do not assume any responsibility
        to any third party in respect of the performance of our duties to you.
      </p>
      <h2>LINKS TO OUR SITE OR OTHER SITES</h2>
      <p>
        You are allowed to make a link to our site’s homepage from your website
        if the content on your site meets the standards of our Acceptable Use
        Policy and provided you do not suggest any endorsement by us or
        association with us unless we provide agreement in writing. We reserve
        the right to end this permission at any time.
      </p>
      <p>
        Links from our site to other sites are only for information. We do not
        accept any responsibility for other sites, the accuracy of their content
        or any loss you may suffer from using and relying on them.
      </p>
      <h2>COMPUTER OFFENCES</h2>
      <p>
        If you do anything which is a criminal offence under the Computer Misuse
        Act 1990 (such as for example introducing viruses, worms, Trojans and
        other technologically harmful or damaging material) your right to use
        the site will end immediately, we will report you to the relevant
        authorities and provide them with details of your identity.
      </p>
      <p>
        You must not try to obtain access to our server or any connected
        database or make any 'attack' on the site.
      </p>
      <h2>JURISDICTION</h2>
      <p>
        These Terms and Conditions shall be governed by and construed in
        accordance with the laws of England and Wales. Any matters arising in
        connection to this agreement shall be subject to the exclusive
        jurisdiction of the courts of England and Wales.
      </p>
      <h2>COMPLAINTS</h2>
      <p>
        If you have any questions or a complaint about the service provided by
        us, please send us an email at info@grapevine.travel to make your
        complaint.
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Terms'
};
</script>

<style scoped></style>
