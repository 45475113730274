<template>
  <div class="technology">
    <section class="section section-hero section-img-right">
      <svg
        class="section-img-bg"
        width="100%"
        viewBox="0 0 1440 712"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0,-1000L1440,-1000L1440,556L0,712" fill="#0D1040" />
      </svg>
      <div class="container">
        <div class="row align-items-center">
          <div class="col-12 col-md-6">
            <h1 class="section-title h1">
              About Grapevine
            </h1>

            <p>
              Built by business travellers, for business travellers. We
              understand the pain points for the end user.
              <br /><strong>That's all about to change.</strong>
            </p>
          </div>
          <div class="col-12 col-md-6 pl-md-0">
            <app-image
              alt="About Grapevine"
              src="/images/about/Hero.png"
            ></app-image>
          </div>
        </div>
      </div>
    </section>

    <section class="section section-team">
      <div class="container">
        <h2 class="section-title">
          The Team
        </h2>

        <div class="team-grid">
          <div class="team-grid__item">
            <app-image
              alt="Jack Dow"
              src="/images/about/team/Jack.png"
              no-mobile
            ></app-image>
            <h3 class="team-grid__name">Jack Dow</h3>
            <div class="team-grid__position">Founder & CEO</div>
            <a
              href="https://www.linkedin.com/in/jack-dow-2a306126/"
              target="_blank"
              rel="noopener noreferrer"
            >
              LinkedIn
            </a>
          </div>

          <div class="team-grid__item">
            <app-image
              alt="Barry Cranston"
              src="/images/about/team/Barry.png"
              no-mobile
            ></app-image>
            <h3 class="team-grid__name">Barry Cranston</h3>
            <div class="team-grid__position">Head of Product</div>
            <a
              href="https://www.linkedin.com/in/barrycranston/"
              target="_blank"
              rel="noopener noreferrer"
            >
              LinkedIn
            </a>
          </div>

          <div class="team-grid__item">
            <app-image
              alt="Alejandro Fernandes Antunes"
              src="/images/about/team/Ale.png"
              no-mobile
            ></app-image>
            <h3 class="team-grid__name">Alejandro Fernandes Antunes</h3>
            <div class="team-grid__position">Lead Engineer</div>
            <a
              href="https://www.linkedin.com/in/alejandro-fernandes-antunes-uy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              LinkedIn
            </a>
          </div>

          <div class="team-grid__item">
            <app-image
              alt="Stephen Simmons"
              src="/images/about/team/Stephen.png"
              no-mobile
            ></app-image>
            <h3 class="team-grid__name">Stephen Simmons</h3>
            <div class="team-grid__position">AI & Data Science</div>
            <a
              href="https://www.linkedin.com/in/stephen-simmons-6bb6954/"
              target="_blank"
              rel="noopener noreferrer"
            >
              LinkedIn
            </a>
          </div>

          <div class="team-grid__item">
            <app-image
              alt="Will Phillipson"
              src="/images/about/team/Will.png"
              no-mobile
            ></app-image>
            <h3 class="team-grid__name">Will Phillipson</h3>
            <div class="team-grid__position">Advisor</div>
            <a
              href="https://www.linkedin.com/in/willphillipson/"
              target="_blank"
              rel="noopener noreferrer"
            >
              LinkedIn
            </a>
          </div>

          <div class="team-grid__item">
            <app-image
              alt="Gellan Watt"
              src="/images/about/team/Gellen.png"
              no-mobile
            ></app-image>
            <h3 class="team-grid__name">Gellan Watt</h3>
            <div class="team-grid__position">Advisor</div>
            <a
              href="https://www.linkedin.com/in/gellanwatt/"
              target="_blank"
              rel="noopener noreferrer"
            >
              LinkedIn
            </a>
          </div>
        </div>
      </div>
    </section>

    <section class="section section-mission section-bg-secondary">
      <img
        src="/images/mission-bg-top.svg"
        class="section-mission__bg--top"
        alt=""
      />

      <div class="container">
        <h2 class="section-title">
          Our Mission
        </h2>

        <div class="row">
          <div class="col-12 col-md-6">
            <p>
              Grapevine's mission is to help Travel Management Companies (TMCs)
              significantly increase ancillary attachment rates and capture
              in-destination spend. Whilst TMCs are great at selling flights,
              ancillaries have proved more of a challenge, with
              <small>></small>70% of hotel bookings being leaked to off platform
              sources and next to no in-destination spend is being captured.
            </p>
            <p>
              A huge amount of revenue being left on the table and
              <strong>our mission is to change that</strong>
            </p>
          </div>
          <div class="col-12 col-md-6">
            <p>
              Why now? Because pressure on air margins and low travel volumes
              mean that increasing revenues per trip is
              <strong>more important than ever.</strong> Business travellers
              expect nothing less than consumer-grade personalisation and
              Grapevine gives TMCs the tools to solve these challenges.
            </p>
            <p>
              Business travellers get the travel companion they have always
              dreamed of, clients have the TMC promise fulfilled and TMCs
              benefit from a powerful flywheel to driving revenues and profit
              margins.
            </p>
          </div>
        </div>
      </div>

      <img
        src="/images/mission-bg-bottom.svg"
        class="section-mission__bg--bottom"
        alt=""
      />
    </section>

    <section class="section section-map">
      <app-image
        class="section-map__image"
        alt="About Grapevine"
        src="/images/about/Map.png"
      ></app-image>

      <img
        class="section-map__icon"
        src="/images/logo-icon.svg"
        alt="About Grapevine"
      />

      <div class="section-map__card">
        <div class="section-map__title">
          Come visit us
        </div>
        We're based in the heart of West London.
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'About'
};
</script>

<style lang="scss" scoped>
@import 'src/scss/utils/__utils';
.technology {
  overflow: hidden;
}

.section-team {
  @media (min-width: 551px) and (max-width: 1024px) {
    .section-title {
      text-align: center;
      margin-bottom: 60px;

      &:after {
        left: calc(50% - 40px);
      }
    }
  }
}

.team-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, 174px);
  grid-gap: 24px 8px;
  justify-content: space-between;
  margin-top: 60px;
  text-align: center;

  &__name {
    margin: 0;
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
  }

  &__position {
    margin-bottom: 4px;
    font-size: 18px;
    line-height: 22px;
    color: $light;
    font-weight: 600;
  }

  a {
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
  }

  picture {
    width: 140px;
    margin: 0 auto 24px;
    filter: grayscale(1);
  }

  @media (max-width: 1199px) {
    grid-template-columns: repeat(auto-fit, 140px);

    &__name {
      font-size: 14px;
      line-height: 18px;
    }

    &__position {
      font-size: 14px;
      line-height: 18px;
    }
  }

  @media (min-width: 551px) and (max-width: 1024px) {
    grid-template-columns: repeat(3, 140px);
    justify-content: space-around;
  }

  @media (max-width: 550px) {
    grid-template-columns: repeat(auto-fit, 140px);
  }
}

.section-mission {
  position: relative;
  margin-top: 100px;
  padding: 70px 0 110px;
  z-index: 5;

  small {
    vertical-align: text-top;
  }

  &__bg {
    &--top,
    &--bottom {
      position: absolute;
      left: 50%;
      height: auto;
      width: 100%;
      min-width: 1440px;
      background-size: 100%;
    }

    &--top {
      top: 1px;
      transform: translate(-50%, -100%);
    }

    &--bottom {
      bottom: 1px;
      transform: translate(-50%, 100%);
    }
  }

  @media (min-width: 768px) {
    .section-title {
      text-align: center;
      margin-bottom: 60px;

      &:after {
        left: calc(50% - 40px);
      }
    }

    .col-6 {
      &:first-child {
        padding-left: 40px;
      }

      &:last-child {
        padding-right: 40px;
      }
    }
  }

  @media (max-width: 767px) {
    padding: 0 0 60px;
    margin-top: 40px;
  }
}

.section-map {
  padding: 0;
  position: relative;
  margin-bottom: 70px;

  &__image {
    width: 100%;

    :deep(img) {
      min-height: 480px;
      object-fit: cover;

      @media (max-width: 767px) {
        min-height: 320px;
        //height: 320px;
      }
    }
  }

  &__icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 48px;
    height: auto;
  }

  &__card {
    padding: 24px 24px 32px;
    text-align: center;
    background-color: #fff;
    border: 1px solid $border-color;
    border-radius: 8px;
    position: absolute;
    width: 480px;
    max-width: calc(100% - 32px);
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 28px);
    font-weight: 600;
  }

  &__title {
    font-size: 24px;
    line-height: 20px;
    color: $primary;
    font-weight: bold;
    margin-bottom: 8px;
  }
}
</style>
